import React, { useState, useEffect } from "react";
import styles, { tableHeaderStyles, mobileStyles } from './style';
import './index.css';
const App = () => {
  const [step, setStep] = useState(1);
  const [tournamentName, setTournamentName] = useState("");
  const [participantCount, setParticipantCount] = useState("");
  const [participants, setParticipants] = useState([]);
  const [fixtures, setFixtures] = useState([]);
  const [leagueTable, setLeagueTable] = useState([]);
  const [matchType, setMatchType] = useState("");
  const [tempScores, setTempScores] = useState({});
  const [champion, setChampion] = useState(null);
  const [selectedSystem, setSelectedSystem] = useState(null);
  const [challengeStep, setChallengeStep] = useState(1);
  const [gladiator1, setGladiator1] = useState("");
  const [gladiator2, setGladiator2] = useState("");
  const [bestOf, setBestOf] = useState("");
  const [challengeFixtures, setChallengeFixtures] = useState([]);
  const [challengeLeagueTable, setChallengeLeagueTable] = useState([]);
  const [winner, setWinner] = useState(null);
  const [eliminationParticipants, setEliminationParticipants] = useState([]);
  const [eliminationFixtures, setEliminationFixtures] = useState([]);
  const [eliminationRound, setEliminationRound] = useState(1);
  const [eliminationWinner, setEliminationWinner] = useState(null);
  const [groupParticipants, setGroupParticipants] = useState([]);
  const [groupCount, setGroupCount] = useState("");
  const [formattedGroups, setFormattedGroups] = useState([]);
  const [matchCount, setMatchCount] = useState("");
  const [groupFixtures, setGroupFixtures] = useState({});
  const [groupStandings, setGroupStandings] = useState({});
  const [selectedGroup, setSelectedGroup] = useState(0); // Başlangıçta ilk grup seçili
  const [groupsCompleted, setGroupsCompleted] = useState(
    Array(formattedGroups.length).fill(false)
  );
  const [knockoutFixtures, setKnockoutFixtures] = useState([]);
  const [teamsAdvancing, setTeamsAdvancing] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const [authType, setAuthType] = useState("login"); // 'login' veya 'register'
  const [user, setUser] = useState(null);
  const [tournaments, setTournaments] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [knockoutResults, setKnockoutResults] = useState([]); // Yeni eklenen state
  const [allEliminationMatches, setAllEliminationMatches] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const tournamentsPerPage = 20;
  const [sortedTournaments, setSortedTournaments] = useState([]);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  useEffect(() => {
    const sorted = [...tournaments].sort((a, b) => {
      // Önce şampiyonu belirlenmiş turnuvaları sırala
      if (a.champion && !b.champion) return -1;
      if (!a.champion && b.champion) return 1;
      
      // Şampiyon durumu aynıysa tarihe göre sırala
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    setSortedTournaments(sorted);
  }, [tournaments]);

  useEffect(() => {
    fetchTournaments();
  }, []);
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);
  useEffect(() => {
    const savedState = localStorage.getItem("tournamentState");
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      setStep(parsedState.step);
      setTournamentName(parsedState.tournamentName);
      setParticipantCount(parsedState.participantCount);
      setParticipants(parsedState.participants);
      setFixtures(parsedState.fixtures);
      setLeagueTable(parsedState.leagueTable);
      setMatchType(parsedState.matchType);
      setTempScores(parsedState.tempScores);
      setChampion(parsedState.champion);
    }
  }, []);
  useEffect(() => {
    if (step === 13) {
      const newFixtures = {};
      formattedGroups.forEach((group, index) => {
        newFixtures[index] = generateGroupFixtures(group, matchCount);
      });
      setGroupFixtures(newFixtures);

      const newStandings = {};
      formattedGroups.forEach((group, index) => {
        newStandings[index] = group.map((team) => ({
          name: team.name,
          played: 0,
          won: 0,
          drawn: 0,
          lost: 0,
          goalsFor: 0,
          goalsAgainst: 0,
          goalDifference: 0,
          points: 0,
        }));
      });
      setGroupStandings(newStandings);
    }
  }, [step, formattedGroups, matchCount]);
  useEffect(() => {
    const stateToSave = {
      step,
      tournamentName,
      participantCount,
      participants,
      fixtures,
      leagueTable,
      matchType,
      tempScores,
      champion,
    };
    localStorage.setItem("tournamentState", JSON.stringify(stateToSave));
  }, [
    step,
    tournamentName,
    participantCount,
    participants,
    fixtures,
    leagueTable,
    matchType,
    tempScores,
    champion,
  ]);
  const indexOfLastTournament = currentPage * tournamentsPerPage;
  const indexOfFirstTournament = indexOfLastTournament - tournamentsPerPage;
  const currentTournaments = sortedTournaments.slice(indexOfFirstTournament, indexOfLastTournament);

const pageNumbers = [];
for (let i = 1; i <= Math.ceil(sortedTournaments.length / tournamentsPerPage); i++) {
  pageNumbers.push(i);
}

const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const saveEliminationTournament = async () => {
    const tournamentData = {
      name: tournamentName,
      type: 'elimination',
      knockoutFixtures: allEliminationMatches.map(match => ({
        home: match.home.name,
        away: match.away.name,
        homeScore: match.homeScore,
        awayScore: match.awayScore,
        isCompleted: true
      })),
      champion: eliminationWinner ? eliminationWinner.name : null,
    };
  
    try {
      const response = await fetch('https://turnuva-app-backend.vercel.app/api/tournaments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`,
        },
        body: JSON.stringify(tournamentData),
      });
  
      if (response.ok) {
        const savedTournament = await response.json();
        console.log('Eleme turnuvası kaydedildi:', savedTournament);
      } else {
        const errorData = await response.json();
        console.error('Eleme turnuvası kaydedilemedi. Hata:', errorData);
      }
    } catch (error) {
      console.error('Hata:', error);
    }
  };
  const saveChallengeTournament = async () => {
    const tournamentData = {
      name: `${gladiator1} vs ${gladiator2}`,
      type: 'challenge',
      challengeFixtures: challengeFixtures.map(match => ({
        home: match.home,
        away: match.away,
        homeScore: match.homeScore,
        awayScore: match.awayScore,
        isCompleted: match.isCompleted
      })),
      challengeLeagueTable: challengeLeagueTable,
      champion: winner,
    };
  
    try {
      const headers = {
        'Content-Type': 'application/json',
      };
  
      if (user && user.token) {
        headers['Authorization'] = `Bearer ${user.token}`;
      }
  
      const response = await fetch('https://turnuva-app-backend.vercel.app/api/tournaments', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(tournamentData),
      });
  
      if (response.ok) {
        const savedTournament = await response.json();
        console.log('Meydan okuma turnuvası kaydedildi:', savedTournament);
      } else {
        const errorData = await response.json();
        console.error('Meydan okuma turnuvası kaydedilemedi. Hata:', errorData);
      }
    } catch (error) {
      console.error('Hata:', error);
    }
  };
  
  const clearCache = () => {
    localStorage.removeItem('tournamentState');
    window.location.reload();
  };
  const handleGroupParticipantChange = (index, field, value) => {
    const updatedParticipants = [...groupParticipants];
    updatedParticipants[index] = {
      ...updatedParticipants[index],
      [field]: value,
    };
    setGroupParticipants(updatedParticipants);
  };
  const handleGroupsSubmit = () => {
    const seededParticipants = groupParticipants.filter((p) => p.isSeeded);
    const unseededParticipants = groupParticipants.filter((p) => !p.isSeeded);

    // Seri başı olanları ve olmayanları karıştır
    const shuffledSeeded = seededParticipants.sort(() => 0.5 - Math.random());
    const shuffledUnseeded = unseededParticipants.sort(
      () => 0.5 - Math.random()
    );

    const groups = Array.from({ length: parseInt(groupCount) }, () => []);

    // Seri başı olanları gruplara dağıt
    shuffledSeeded.forEach((participant, index) => {
      groups[index % groups.length].push(participant);
    });

    // Seri başı olmayanları gruplara dağıt
    shuffledUnseeded.forEach((participant, index) => {
      groups[index % groups.length].push(participant);
    });

    setFormattedGroups(groups);
    setStep(12); // Yeni adım için
  };
  const handleSystemSelect = (system) => {
    setSelectedSystem(system);
    if (system === "challenge") {
      setChallengeStep(1);
      setStep(7);
    } else if (system === "elimination") {
      setStep(8);
    } else if (system === "groups") {
      setStep(11); // Yeni adım için
    } else if (system === "league") {
      setStep(2); // Eski "Gruplar" (şimdi "Lig") için
    }
  };

  const handleTournamentNameSubmit = () => {
    if (tournamentName.trim() !== "") {
      setStep(3);
    }
  };

  const handleParticipantCountSubmit = () => {
    if (participantCount > 1) {
      setStep(4);
      setParticipants(Array(parseInt(participantCount)).fill(""));
    }
  };

  const handleParticipantNameChange = (index, name) => {
    const newParticipants = [...participants];
    newParticipants[index] = name;
    setParticipants(newParticipants);
  };

  const handleParticipantsSubmit = () => {
    if (participants.every((name) => name.trim() !== "")) {
      setStep(5);
    }
  };

  const handleMatchTypeSubmit = () => {
    if (matchType) {
      setStep(6);
      generateFixtures();
      initializeLeagueTable();
    }
  };

  const handleEliminationParticipantCountSubmit = () => {
    if ([4, 8, 16, 32, 64].includes(Number(participantCount))) {
      setStep(9);
      setEliminationParticipants(
        Array(Number(participantCount)).fill({ name: "", isSeeded: false })
      );
    }
  };
  const handleEliminationParticipantNameChange = (index, name, isSeeded) => {
    const newParticipants = [...eliminationParticipants];
    newParticipants[index] = { name, isSeeded };
    setEliminationParticipants(newParticipants);
  };

  const getRoundName = (remainingTeams) => {
    switch (remainingTeams) {
      case 8:
        return "Çeyrek Final";
      case 4:
        return "Yarı Final";
      case 2:
        return "Final";
      default:
        return "1. Tur";
    }
  };

  const endTournament = () => {
    setShowConfirmation(true);
  };
  
  const confirmEndTournament = () => {
    localStorage.removeItem('tournamentState');
    setStep(1);
    setTournamentName('');
    setParticipantCount('');
    setParticipants([]);
    setFixtures([]);
    setLeagueTable([]);
    setMatchType('');
    setTempScores({});
    setChampion(null);
    setGroupsCompleted(Array(formattedGroups.length).fill(false));
    setKnockoutFixtures([]);
    setKnockoutResults([]);
    setShowConfirmation(false);
  
    if (champion) {
      saveTournament(); // Şampiyon belirlendiyse turnuvayı kaydet
    }
  };
  
  const saveTournament = async () => {
    let tournamentData;
  
    if (selectedSystem === 'league') {
      tournamentData = {
        name: tournamentName,
        type: 'league',
        fixtures: fixtures.flatMap(round => round.matches.map(match => ({
          home: match.home,
          away: match.away,
          homeScore: match.homeScore,
          awayScore: match.awayScore,
          isCompleted: match.isCompleted
        }))),
        leagueTable,
        champion,
      };
    } else if (selectedSystem === 'groups') {
      tournamentData = {
        name: tournamentName,
        type: 'groups',
        groups: formattedGroups.map((group, index) => ({
          name: `Grup ${String.fromCharCode(65 + index)}`,
          fixtures: groupFixtures[index].flatMap(week => week.matches.map(match => ({
            home: match.home.name,
            away: match.away.name,
            homeScore: match.homeScore,
            awayScore: match.awayScore,
            isCompleted: match.isConfirmed
          }))),
          standings: groupStandings[index]
        })),
        knockoutFixtures: knockoutFixtures.map(match => ({
          home: match.home.name,
          away: match.away.name,
          homeScore: match.homeScore,
          awayScore: match.awayScore,
          isCompleted: match.isConfirmed
        })),
        knockoutResults: knockoutResults.map(match => ({
          home: match.home.name,
          away: match.away.name,
          homeScore: match.homeScore,
          awayScore: match.awayScore,
          isCompleted: match.isConfirmed
        })),
        champion,
      };
    } else if (selectedSystem === 'elimination') {
      tournamentData = {
        name: tournamentName,
        type: 'elimination',
        knockoutFixtures: allEliminationMatches.map(match => ({
          home: match.home.name,
          away: match.away.name,
          homeScore: match.homeScore,
          awayScore: match.awayScore,
          isCompleted: true
        })),
        champion: eliminationWinner ? eliminationWinner.name : null,
      };
    } else if (selectedSystem === 'challenge') {
      await saveChallengeTournament();
      return;
    }
  
    // creator alanını ekleyelim
    tournamentData.creator = user ? user.userId : null;
  
    console.log('Gönderilecek veri:', JSON.stringify(tournamentData, null, 2));
  
    try {
      const headers = {
        'Content-Type': 'application/json',
      };
  
      if (user && user.token) {
        headers['Authorization'] = `Bearer ${user.token}`;
      }
  
      const response = await fetch('https://turnuva-app-backend.vercel.app/api/tournaments', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(tournamentData),
      });
  
      if (response.ok) {
        const savedTournament = await response.json();
        console.log('Turnuva kaydedildi:', savedTournament);
      } else {
        const errorData = await response.json();
        console.error('Turnuva kaydedilemedi. Hata:', errorData);
      }
    } catch (error) {
      console.error('Hata:', error);
    }
  };
  
  
  const fetchTournaments = async () => {
    try {
      const response = await fetch('https://turnuva-app-backend.vercel.app/api/tournaments', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setTournaments(data);
      } else {
        console.error('Turnuvalar getirilemedi');
      }
    } catch (error) {
      console.error('Hata:', error);
    }
  };

  const fetchTournamentDetails = async (tournamentId) => {
    try {
      const response = await fetch(`https://turnuva-app-backend.vercel.app/api/tournaments/${tournamentId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      if (response.ok) {
        const data = await response.json();
        
        if (data.type === 'league') {
          setSelectedTournament(data);
        } else if (data.type === 'groups') {
          const formattedData = {
            ...data,
            groups: data.groups.map(group => ({
              ...group,
              fixtures: group.fixtures.map(match => ({
                ...match,
                home: typeof match.home === 'object' ? match.home.name : match.home,
                away: typeof match.away === 'object' ? match.away.name : match.away,
              })),
            })),
            knockoutFixtures: data.knockoutFixtures.map(match => ({
              ...match,
              home: typeof match.home === 'object' ? match.home.name : match.home,
              away: typeof match.away === 'object' ? match.away.name : match.away,
            })),
          };
          setSelectedTournament(formattedData);
        } else if (data.type === 'elimination') {
          setSelectedTournament(data);
        } else if (data.type === 'challenge') {
          setSelectedTournament(data);
        } else {
          setSelectedTournament(data);
        }
      } else {
        console.error('Turnuva detayları getirilemedi');
      }
    } catch (error) {
      console.error('Hata:', error);
    }
  };
  const getEmojiForTournamentType = (type) => {
    switch (type) {
      case 'league':
        return '⚽';
      case 'groups':
        return '👥';
      case 'elimination':
        return '🏅';
      case 'challenge':
        return '💪';
      default:
        return '🏆';
    }
  };
  
  const updateKnockoutScore = (matchIndex, team, score) => {
    const newFixtures = [...knockoutFixtures];
    newFixtures[matchIndex][team === "home" ? "homeScore" : "awayScore"] =
      parseInt(score);
    setKnockoutFixtures(newFixtures);
  };

  const confirmKnockoutScore = (matchIndex) => {
    const newFixtures = [...knockoutFixtures];
    const match = newFixtures[matchIndex];
    if (
      match.homeScore !== null &&
      match.awayScore !== null &&
      match.homeScore !== match.awayScore
    ) {
      match.isConfirmed = true;
      setKnockoutFixtures(newFixtures);
  
      const allMatchesConfirmed = newFixtures.every(
        (match) => match.isConfirmed
      );
      if (allMatchesConfirmed) {
        const advancingTeams = newFixtures.map((match) =>
          match.homeScore > match.awayScore ? match.home : match.away
        );
  
        if (advancingTeams.length === 1) {
          const champion = advancingTeams[0];
          setChampion(champion.name); // Şampiyonu belirle
        } else {
          setKnockoutResults(prevResults => [...prevResults, ...newFixtures]); // Yeni eklenen kod
          createNextRoundFixtures(advancingTeams);
        }
      }
    }
  };
  
  const createNextRoundFixtures = (advancingTeams) => {
    const nextRoundFixtures = [];
    for (let i = 0; i < advancingTeams.length; i += 2) {
      nextRoundFixtures.push({
        home: advancingTeams[i],
        away: advancingTeams[i + 1],
        homeScore: null,
        awayScore: null,
      });
    }
  
    setKnockoutFixtures(nextRoundFixtures);
    setStep(14); // Bir sonraki adım
  };
  

  const editKnockoutScore = (matchIndex) => {
    const newFixtures = [...knockoutFixtures];
    const match = newFixtures[matchIndex];
    match.homeScore = null;
    match.awayScore = null;
    match.isConfirmed = false;
    setKnockoutFixtures(newFixtures);
  };

  const finishGroup = (groupIndex) => {
    const newGroupsCompleted = [...groupsCompleted];
    newGroupsCompleted[groupIndex] = true;
    setGroupsCompleted(newGroupsCompleted);

    if (newGroupsCompleted.every((completed) => completed)) {
      createKnockoutFixtures();
    }
  };
  const createKnockoutFixtures = () => {
    const advancingTeams = [];
  
    // Her gruptan çıkacak takımları listeye ekleyin
    for (let i = 0; i < Object.keys(groupStandings).length; i++) {
      const groupStanding = groupStandings[i];
      for (let j = 0; j < teamsAdvancing; j++) {
        advancingTeams.push({ ...groupStanding[j], groupIndex: i, position: j + 1 });
      }
    }
  
    // Takımları pozisyon ve grup indeksine göre sıralayın
    advancingTeams.sort((a, b) => a.position - b.position || a.groupIndex - b.groupIndex);
  
    // Eşleşmeleri belirle
    const knockoutFixtures = [];
    const numGroups = Object.keys(groupStandings).length;
  
    if (teamsAdvancing === 2) {
      for (let i = 0; i < numGroups; i++) {
        const group1Leader = advancingTeams.filter(
          (team) => team.groupIndex === i && team.position === 1
        )[0]; // İlk lider takım
        const group2Second = advancingTeams.filter(
          (team) => team.groupIndex === (i + 1) % numGroups && team.position === 2
        )[0]; // İlk ikinci takım
  
        if (group1Leader && group2Second) {
          knockoutFixtures.push({
            home: group1Leader,
            away: group2Second,
            homeScore: null,
            awayScore: null,
          });
        }
      }
    } else {
      // Diğer durumlar için mevcut mantığı kullan
      const half = advancingTeams.length / 2;
      for (let i = 0; i < half; i++) {
        let home = advancingTeams[i];
        let away = advancingTeams[advancingTeams.length - 1 - i];
  
        // Grup liderlerini her zaman ev sahibi yap
        if (home.position > away.position) {
          [home, away] = [away, home];
        }
  
        knockoutFixtures.push({
          home: home,
          away: away,
          homeScore: null,
          awayScore: null,
        });
      }
    }
  
    setKnockoutFixtures(knockoutFixtures);
    setStep(14);
  };
  
  
  

  const startEliminationTournament = () => {
    if (eliminationParticipants.every((p) => p.name.trim() !== "")) {
      setStep(10);
      generateEliminationFixtures();
    }
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const updateGroupScore = (groupIndex, weekIndex, matchIndex, team, score) => {
    const newFixtures = { ...groupFixtures };
    newFixtures[groupIndex][weekIndex].matches[matchIndex][
      team === "home" ? "homeScore" : "awayScore"
    ] = parseInt(score);
    setGroupFixtures(newFixtures);

    // Skor güncellendiğinde grubu sonlandır butonunu yeniden aktif yap
    const newGroupsCompleted = [...groupsCompleted];
    newGroupsCompleted[groupIndex] = false;
    setGroupsCompleted(newGroupsCompleted);
  };

  const confirmGroupScore = (groupIndex, weekIndex, matchIndex) => {
    const newFixtures = { ...groupFixtures };
    const match = newFixtures[groupIndex][weekIndex].matches[matchIndex];
    if (match.homeScore !== null && match.awayScore !== null) {
      match.isConfirmed = true;
      setGroupFixtures(newFixtures);
      updateGroupStandings(groupIndex);

      // Tüm skorlar onaylanınca yeni eşleşmeleri oluştur
      const allScoresConfirmed = Object.values(groupFixtures)
        .flat()
        .every((week) => week.matches.every((match) => match.isConfirmed));

      if (allScoresConfirmed) {
        createKnockoutFixtures();
      }
    }
  };

  const editGroupScore = (groupIndex, weekIndex, matchIndex) => {
    const newFixtures = { ...groupFixtures };
    const match = newFixtures[groupIndex][weekIndex].matches[matchIndex];
    match.homeScore = null;
    match.awayScore = null;
    match.isConfirmed = false;
    setGroupFixtures(newFixtures);
    updateGroupStandings(groupIndex);

    // Skor iptal edildiğinde grubu sonlandır butonunu yeniden aktif yap
    const newGroupsCompleted = [...groupsCompleted];
    newGroupsCompleted[groupIndex] = false;
    setGroupsCompleted(newGroupsCompleted);
  };

  const updateGroupStandings = (groupIndex) => {
    const newStandings = formattedGroups[groupIndex].map((team) => ({
      name: team.name,
      played: 0,
      won: 0,
      drawn: 0,
      lost: 0,
      goalsFor: 0,
      goalsAgainst: 0,
      goalDifference: 0,
      points: 0,
    }));

    groupFixtures[groupIndex].forEach((week) => {
      week.matches.forEach((match) => {
        if (match.isConfirmed) {
          const homeTeam = newStandings.find(
            (team) => team.name === match.home.name
          );
          const awayTeam = newStandings.find(
            (team) => team.name === match.away.name
          );

          if (homeTeam && awayTeam) {
            homeTeam.played += 1;
            awayTeam.played += 1;

            homeTeam.goalsFor += match.homeScore;
            homeTeam.goalsAgainst += match.awayScore;
            awayTeam.goalsFor += match.awayScore;
            awayTeam.goalsAgainst += match.homeScore;

            if (match.homeScore > match.awayScore) {
              homeTeam.won += 1;
              awayTeam.lost += 1;
              homeTeam.points += 3;
            } else if (match.homeScore < match.awayScore) {
              awayTeam.won += 1;
              homeTeam.lost += 1;
              awayTeam.points += 3;
            } else {
              homeTeam.drawn += 1;
              awayTeam.drawn += 1;
              homeTeam.points += 1;
              awayTeam.points += 1;
            }

            homeTeam.goalDifference = homeTeam.goalsFor - homeTeam.goalsAgainst;
            awayTeam.goalDifference = awayTeam.goalsFor - awayTeam.goalsAgainst;
          }
        }
      });
    });

    newStandings.sort(
      (a, b) => b.points - a.points || b.goalDifference - a.goalDifference
    );
    setGroupStandings((prevStandings) => ({
      ...prevStandings,
      [groupIndex]: newStandings,
    }));
  };

  const generateGroupFixtures = (group, matchCount) => {
    const teams = [...group];
    let fixtures = [];

    if (teams.length % 2 !== 0) {
      teams.push({ name: "Bye" });
    }

    const totalRounds = teams.length - 1;
    const halfSize = teams.length / 2;

    const homeCount = {};
    const awayCount = {};

    teams.forEach((team) => {
      homeCount[team.name] = 0;
      awayCount[team.name] = 0;
    });

    for (let round = 0; round < totalRounds; round++) {
      let weekFixtures = [];
      for (let i = 0; i < halfSize; i++) {
        let home = teams[i];
        let away = teams[teams.length - 1 - i];
        if (home.name !== "Bye" && away.name !== "Bye") {
          if (homeCount[home.name] > awayCount[home.name]) {
            [home, away] = [away, home];
          }
          weekFixtures.push({
            home: home,
            away: away,
            homeScore: null,
            awayScore: null,
          });
          homeCount[home.name]++;
          awayCount[away.name]++;
        }
      }
      fixtures.push({ week: round + 1, matches: weekFixtures });

      const lastTeam = teams.pop();
      teams.splice(1, 0, lastTeam);
    }

    if (matchCount === "double") {
      const secondHalfFixtures = fixtures.map((round) => ({
        week: round.week + totalRounds,
        matches: round.matches.map((match) => ({
          home: match.away,
          away: match.home,
          homeScore: null,
          awayScore: null,
        })),
      }));
      fixtures = [...fixtures, ...secondHalfFixtures];
    }

    return fixtures;
  };

  const generateEliminationFixtures = () => {
    const shuffled = [...eliminationParticipants].sort(
      () => Math.random() - 0.5
    );
    const seeded = shuffled.filter((p) => p.isSeeded);
    const unseeded = shuffled.filter((p) => !p.isSeeded);

    let fixtures = [];
    for (let i = 0; i < shuffled.length / 2; i++) {
      if (seeded.length > 0 && unseeded.length > 0) {
        fixtures.push({
          home: seeded.pop(),
          away: unseeded.pop(),
          homeScore: null,
          awayScore: null,
        });
      } else {
        const home = seeded.pop() || unseeded.pop();
        const away = seeded.pop() || unseeded.pop();
        fixtures.push({ home, away, homeScore: null, awayScore: null });
      }
    }

    setEliminationFixtures(fixtures);
    setEliminationRound(Math.log2(shuffled.length));
  };
  const updateEliminationScore = (index, team, score) => {
    const newFixtures = [...eliminationFixtures];
    if (team === "home") {
      newFixtures[index].homeScore = score;
    } else {
      newFixtures[index].awayScore = score;
    }
    setEliminationFixtures(newFixtures);
  };
  const editEliminationScore = (index) => {
    const newFixtures = [...eliminationFixtures];
    const match = newFixtures[index];

    if (match.isCompleted) {
      match.homeScore = null;
      match.awayScore = null;
      match.isCompleted = false;
      setEliminationFixtures(newFixtures);
    }
  };
  const confirmEliminationScore = (index) => {
    const newFixtures = [...eliminationFixtures];
    const match = newFixtures[index];

    if (
      match.homeScore !== null &&
      match.awayScore !== null &&
      match.homeScore !== match.awayScore
    ) {
      match.isCompleted = true;
      setEliminationFixtures(newFixtures);

      if (eliminationFixtures.every((match) => match.isCompleted)) {
        advanceEliminationRound();
      }
    }
  };

  const advanceEliminationRound = () => {
    const winners = eliminationFixtures.map((match) =>
      match.homeScore > match.awayScore ? match.home : match.away
    );
  
    setAllEliminationMatches(prevMatches => [...prevMatches, ...eliminationFixtures]);
  
    if (winners.length === 1) {
      setEliminationWinner(winners[0]);
    } else {
      const newFixtures = [];
      for (let i = 0; i < winners.length; i += 2) {
        newFixtures.push({
          home: winners[i],
          away: winners[i + 1],
          homeScore: null,
          awayScore: null,
        });
      }
      setEliminationFixtures(newFixtures);
      setEliminationRound((prevRound) => prevRound - 1);
    }
  };
  const generateFixtures = () => {
    const teams = [...participants];
    let fixtures = [];

    if (teams.length % 2 !== 0) {
      teams.push("Bye");
    }

    const totalRounds = teams.length - 1;
    const halfSize = teams.length / 2;

    const homeCount = {};
    const awayCount = {};

    teams.forEach((team) => {
      homeCount[team] = 0;
      awayCount[team] = 0;
    });

    for (let round = 0; round < totalRounds; round++) {
      let roundFixtures = [];
      for (let i = 0; i < halfSize; i++) {
        let home = teams[i];
        let away = teams[teams.length - 1 - i];
        if (home !== "Bye" && away !== "Bye") {
          if (homeCount[home] > awayCount[home]) {
            [home, away] = [away, home];
          }
          roundFixtures.push({ home, away, homeScore: null, awayScore: null });
          homeCount[home]++;
          awayCount[away]++;
        }
      }
      fixtures.push({ round: round + 1, matches: roundFixtures });

      const lastTeam = teams.pop();
      teams.splice(1, 0, lastTeam);
    }

    if (matchType === "double") {
      const secondHalfFixtures = fixtures.map((round) => ({
        round: round.round + totalRounds,
        matches: round.matches.map((match) => ({
          home: match.away,
          away: match.home,
          homeScore: null,
          awayScore: null,
        })),
      }));
      fixtures = [...fixtures, ...secondHalfFixtures];
    }

    setFixtures(fixtures);
    setTempScores({});
  };

  const generateChallengeFixtures = () => {
    const fixtures = [];
    for (let i = 1; i <= parseInt(bestOf); i++) {
      fixtures.push({
        round: i,
        home: gladiator1,
        away: gladiator2,
        homeScore: null,
        awayScore: null,
        isCompleted: false,
      });
    }
    setChallengeFixtures(fixtures);
    setChallengeLeagueTable([
      { name: gladiator1, won: 0, lost: 0, points: 0 },
      { name: gladiator2, won: 0, lost: 0, points: 0 },
    ]);
  };
  const updateChallengeScore = (index, team, score) => {
    const newFixtures = [...challengeFixtures];
    if (team === "home") {
      newFixtures[index].homeScore = score;
    } else {
      newFixtures[index].awayScore = score;
    }
    setChallengeFixtures(newFixtures);
  };

  const confirmChallengeScore = (index) => {
    const newFixtures = [...challengeFixtures];
    const match = newFixtures[index];

    if (match.homeScore !== null && match.awayScore !== null) {
      match.isCompleted = true;
      setChallengeFixtures(newFixtures);

      const newLeagueTable = [...challengeLeagueTable];
      const homeGladiator = newLeagueTable.find((g) => g.name === match.home);
      const awayGladiator = newLeagueTable.find((g) => g.name === match.away);

      if (match.homeScore > match.awayScore) {
        homeGladiator.won++;
        homeGladiator.points += 3;
        awayGladiator.lost++;
      } else if (match.homeScore < match.awayScore) {
        awayGladiator.won++;
        awayGladiator.points += 3;
        homeGladiator.lost++;
      }

      setChallengeLeagueTable(newLeagueTable);

      const winnerGladiator = newLeagueTable.find(
        (g) => g.won === Math.ceil(parseInt(bestOf) / 2)
      );
      if (winnerGladiator) {
        setWinner(winnerGladiator.name);
      }
    }
  };

  const editChallengeScore = (index) => {
    const newFixtures = [...challengeFixtures];
    const match = newFixtures[index];

    if (match.isCompleted) {
      const newLeagueTable = [...challengeLeagueTable];
      const homeGladiator = newLeagueTable.find((g) => g.name === match.home);
      const awayGladiator = newLeagueTable.find((g) => g.name === match.away);

      if (match.homeScore > match.awayScore) {
        homeGladiator.won--;
        homeGladiator.points -= 3;
        awayGladiator.lost--;
      } else if (match.homeScore < match.awayScore) {
        awayGladiator.won--;
        awayGladiator.points -= 3;
        homeGladiator.lost--;
      }

      match.isCompleted = false;
      match.homeScore = null;
      match.awayScore = null;

      setChallengeFixtures(newFixtures);
      setChallengeLeagueTable(newLeagueTable);
      setWinner(null);
    }
  };
  const initializeLeagueTable = () => {
    const table = participants.map((name) => ({
      name,
      played: 0,
      won: 0,
      drawn: 0,
      lost: 0,
      goalsFor: 0,
      goalsAgainst: 0,
      goalDifference: 0,
      points: 0,
    }));
    setLeagueTable(table);
  };

  const updateTempScore = (roundIndex, matchIndex, homeScore, awayScore) => {
    const key = `$${roundIndex}-$${matchIndex}`;
    setTempScores((prev) => ({
      ...prev,
      [key]: { homeScore, awayScore },
    }));
  };

  const confirmScore = (roundIndex, matchIndex) => {
    const key = `$${roundIndex}-$${matchIndex}`;
    const { homeScore, awayScore } = tempScores[key] || {};

    if (homeScore !== undefined && awayScore !== undefined) {
      const updatedFixtures = [...fixtures];
      const match = updatedFixtures[roundIndex].matches[matchIndex];
      const prevHomeScore = match.homeScore;
      const prevAwayScore = match.awayScore;

      match.homeScore = homeScore;
      match.awayScore = awayScore;
      match.isCompleted = true;
      setFixtures(updatedFixtures);

      updateLeagueTable(
        match.home,
        match.away,
        homeScore,
        awayScore,
        prevHomeScore,
        prevAwayScore
      );

      setTempScores((prev) => {
        const newTempScores = { ...prev };
        delete newTempScores[key];
        return newTempScores;
      });
    }
  };

  const updateLeagueTable = (
    home,
    away,
    homeScore,
    awayScore,
    prevHomeScore,
    prevAwayScore
  ) => {
    const updatedTable = [...leagueTable];
    const homeTeam = updatedTable.find((team) => team.name === home);
    const awayTeam = updatedTable.find((team) => team.name === away);

    if (prevHomeScore !== null && prevAwayScore !== null) {
      homeTeam.played--;
      awayTeam.played--;
      homeTeam.goalsFor -= prevHomeScore;
      homeTeam.goalsAgainst -= prevAwayScore;
      awayTeam.goalsFor -= prevAwayScore;
      awayTeam.goalsAgainst -= prevHomeScore;

      if (prevHomeScore > prevAwayScore) {
        homeTeam.won--;
        homeTeam.points -= 3;
        awayTeam.lost--;
      } else if (prevHomeScore < prevAwayScore) {
        awayTeam.won--;
        awayTeam.points -= 3;
        homeTeam.lost--;
      } else {
        homeTeam.drawn--;
        awayTeam.drawn--;
        homeTeam.points--;
        awayTeam.points--;
      }
    }

    if (homeScore !== null && awayScore !== null) {
      homeTeam.played++;
      awayTeam.played++;
      homeTeam.goalsFor += homeScore;
      homeTeam.goalsAgainst += awayScore;
      awayTeam.goalsFor += awayScore;
      awayTeam.goalsAgainst += homeScore;

      if (homeScore > awayScore) {
        homeTeam.won++;
        homeTeam.points += 3;
        awayTeam.lost++;
      } else if (homeScore < awayScore) {
        awayTeam.won++;
        awayTeam.points += 3;
        homeTeam.lost++;
      } else {
        homeTeam.drawn++;
        awayTeam.drawn++;
        homeTeam.points++;
        awayTeam.points++;
      }
    }

    homeTeam.goalDifference = homeTeam.goalsFor - homeTeam.goalsAgainst;
    awayTeam.goalDifference = awayTeam.goalsFor - awayTeam.goalsAgainst;

    setLeagueTable(
      updatedTable.sort(
        (a, b) =>
          b.points - a.points ||
          b.goalDifference - a.goalDifference ||
          b.goalsFor - a.goalsFor
      )
    );
  };

  const editScore = (roundIndex, matchIndex) => {
    const updatedFixtures = [...fixtures];
    const match = updatedFixtures[roundIndex].matches[matchIndex];

    if (match.isCompleted) {
      updateLeagueTable(
        match.home,
        match.away,
        null,
        null,
        match.homeScore,
        match.awayScore
      );

      match.homeScore = null;
      match.awayScore = null;
      match.isCompleted = false;
      setFixtures(updatedFixtures);

      const key = `$${roundIndex}-$${matchIndex}`;
      setTempScores((prev) => {
        const newTempScores = { ...prev };
        delete newTempScores[key];
        return newTempScores;
      });
    }
  };

  const isAllMatchesCompleted = () => {
    return fixtures.every((round) =>
      round.matches.every(
        (match) =>
          match.away === null ||
          (match.homeScore !== null && match.awayScore !== null)
      )
    );
  };

  useEffect(() => {
    if (isAllMatchesCompleted() && leagueTable.length > 0) {
      setChampion(leagueTable[0].name);
    }
  }, [fixtures, leagueTable]);





  const isMobile = window.innerWidth <= 600;
  const finalStyles = isMobile ? { ...styles, ...mobileStyles } : styles;
  const handleLogin = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;

    try {
      const response = await fetch("https://turnuva-app-backend.vercel.app/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const userData = { email: e.target.email.value, username: "Kullanıcı" };
        setUser(userData);
        localStorage.setItem("user", JSON.stringify(userData));
        setShowLoginForm(false);
      } else {
        alert("Giriş başarısız");
      }
    } catch (error) {
      console.error("Giriş hatası:", error);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const username = e.target.username.value;
    const email = e.target.email.value;
    const password = e.target.password.value;

    try {
      const response = await fetch("https://turnuva-app-backend.vercel.app/api/auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, email, password }),
      });

      if (response.ok) {
        alert("Kayıt başarılı! Lütfen giriş yapın.");
        setAuthType("login");
      } else {
        alert("Kayıt başarısız");
      }
    } catch (error) {
      console.error("Kayıt hatası:", error);
    }
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('user');
  };

  return (
    <div className="bg-gray-900">
      <header className="bg-gray-800 text-white p-4">
        <div className="max-w-6xl mx-auto">
          <div className="flex justify-between items-center">
            <button
              className="bg-red-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition-colors duration-300"
              onClick={clearCache}
            >
              Temizle
            </button>
            <div className="flex items-center space-x-2">
              {user ? (
                <>
                  <span className="text-gray-300 mr-2">Hoş geldiniz, {user.username || user.email}</span>
                  <button
                    className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded transition-colors duration-300"
                    onClick={handleLogout}
                  >
                    Çıkış Yap
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition-colors duration-300"
                    onClick={() => {
                      setShowLoginForm(!showLoginForm);
                      setShowRegisterForm(false);
                    }}
                  >
                    Giriş Yap
                  </button>
                  <button
                    className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded transition-colors duration-300"
                    onClick={() => {
                      setShowRegisterForm(!showRegisterForm);
                      setShowLoginForm(false);
                    }}
                  >
                    Üye Ol
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </header>

      {!user && (showLoginForm || showRegisterForm) && (
        <div className="bg-gray-800 p-4 mt-2">
          <div className="max-w-6xl mx-auto">
            {showLoginForm && (
              <form onSubmit={handleLogin} className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
                <input type="email" name="email" placeholder="E-posta" required className="p-2 bg-gray-700 text-white placeholder-gray-400 rounded flex-grow" />
                <input type="password" name="password" placeholder="Şifre" required className="p-2 bg-gray-700 text-white placeholder-gray-400 rounded flex-grow" />
                <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition-colors duration-300">
                  Giriş Yap
                </button>
              </form>
            )}
            {showRegisterForm && (
              <form onSubmit={handleRegister} className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
                <input type="text" name="username" placeholder="Kullanıcı Adı" required className="p-2 bg-gray-700 text-white placeholder-gray-400 rounded flex-grow" />
                <input type="email" name="email" placeholder="E-posta" required className="p-2 bg-gray-700 text-white placeholder-gray-400 rounded flex-grow" />
                <input type="password" name="password" placeholder="Şifre" required className="p-2 bg-gray-700 text-white placeholder-gray-400 rounded flex-grow" />
                <button type="submit" className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded transition-colors duration-300">
                  Üye Ol
                </button>
              </form>
            )}
          </div>
        </div>
      )}
      {showConfirmation && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-xl text-center">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Sonlandırılsın Mı?</h2>
      <div className="flex justify-center space-x-4">
        <button
          onClick={confirmEndTournament}
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition-colors duration-300"
        >
          Evet
        </button>
        <button
          onClick={() => setShowConfirmation(false)}
          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition-colors duration-300"
        >
          Hayır
        </button>
      </div>
    </div>
  </div>
)}
{step === 1 && (
  <div className="bg-gradient-to-br from-gray-900 to-gray-800 min-h-screen text-white">
    <div className="max-w-7xl mx-auto p-6">
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {[
          { id: "league", icon: "🏆", text: "Lig Turnuvası", desc: "Puan tabanlı kısa soluklu lig", color: "from-green-500 to-green-700" },
          { id: "groups", icon: "🔀", text: "Grup Turnuvası", desc: "Çoklu grup, eleme kombinasyonu", color: "from-yellow-500 to-yellow-700" },
          { id: "elimination", icon: "🥇", text: "Eleme Turnuvası", desc: "Hızlı, tek maçlık eleme sistemi", color: "from-red-500 to-red-700" },
          { id: "challenge", icon: "⚔️", text: "Meydan Okuma", desc: "Bire bir karşılaşmalar", color: "from-purple-500 to-purple-700" },
          { id: "completed", icon: "📊", text: "Tamamlanan Turnuvalar", desc: "Geçmiş turnuva arşivi", color: "from-blue-500 to-blue-700", onClick: () => setStep(15) },
        ].map((system) => (
          <div
            key={system.id}
            className={`bg-gradient-to-br ${system.color} rounded-xl overflow-hidden shadow-lg hover:shadow-2xl transform hover:scale-105 transition-all duration-300 cursor-pointer`}
            onClick={system.onClick || (() => handleSystemSelect(system.id))}
          >
            <div className="p-6">
              <div className="flex items-center justify-between mb-4">
                <span className="text-5xl">{system.icon}</span>
                <div className="bg-white bg-opacity-20 rounded-full p-2">
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </div>
              </div>
              <h2 className="text-2xl font-bold mb-2">{system.text}</h2>
              <p className="text-sm opacity-80">{system.desc}</p>
            </div>
            <div className="bg-black bg-opacity-20 px-6 py-4">
              <span className="text-sm font-semibold">Detaylar için tıklayın</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)}



{step === 2 && (
  <div className="p-6 bg-white shadow-md rounded-lg space-y-6">
    <h1 className="text-2xl font-bold text-gray-800 text-center">Turnuva İsmi</h1>
    
    <input
      type="text"
      value={tournamentName}
      onChange={(e) => setTournamentName(e.target.value)}
      placeholder="Turnuva İsmi"
      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
    />
    
    <div className="flex justify-between">
      <button
        onClick={() => setStep(1)}
        className="bg-red-500 hover:bg-red-600 text-white font-bold py-3 px-4 rounded-lg transition-colors duration-300 flex items-center"
      >
        <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
        </svg>
        Geri
      </button>
      <button
        onClick={handleTournamentNameSubmit}
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-lg transition-colors duration-300 flex items-center"
      >
        Devam Et
        <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </button>
    </div>
  </div>
)}

{step === 3 && (
  <div className="p-6 bg-white shadow-md rounded-lg space-y-6">
    <h1 className="text-2xl font-bold text-gray-800 text-center">Katılımcı Sayısı</h1>
    
    <input
      type="number"
      value={participantCount}
      onChange={(e) => setParticipantCount(e.target.value)}
      placeholder="Katılımcı Sayısı"
      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
    />
    
    <div className="flex justify-between">
      <button
        onClick={() => setStep(2)}
        className="bg-red-500 hover:bg-red-600 text-white font-bold py-3 px-4 rounded-lg transition-colors duration-300 flex items-center"
      >
        <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
        </svg>
        Geri
      </button>
      <button
        onClick={handleParticipantCountSubmit}
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-lg transition-colors duration-300 flex items-center"
      >
        Devam Et
        <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </button>
    </div>
  </div>
)}

{step === 4 && (
  <div className="p-6 bg-white shadow-md rounded-lg space-y-6">
    <h1 className="text-2xl font-bold text-gray-800 text-center">Katılımcı İsimleri</h1>

    {participants.map((participant, index) => (
      <input
        key={index}
        type="text"
        value={participant}
        onChange={(e) => handleParticipantNameChange(index, e.target.value)}
        placeholder={`Katılımcı ${index + 1}`}
        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
      />
    ))}

    <div className="flex justify-between">
      <button
        onClick={() => setStep(3)}
        className="bg-red-500 hover:bg-red-600 text-white font-bold py-3 px-4 rounded-lg transition-colors duration-300 flex items-center"
      >
        <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
        </svg>
        Geri
      </button>
      <button
        onClick={handleParticipantsSubmit}
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-lg transition-colors duration-300 flex items-center"
      >
        Devam Et
        <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </button>
    </div>
  </div>
)}

{step === 5 && (
  <div className="p-6 bg-white shadow-md rounded-lg space-y-6">
    <div className="text-xl font-semibold bg-gray-100 p-3 rounded-lg text-center">
      Maç Türü
    </div>

    <div className="flex justify-around space-x-4">
      <label 
        className={`flex-1 p-4 border rounded-lg cursor-pointer text-center font-bold transition-colors duration-300 ${
          matchType === "single" ? "bg-blue-500 text-white" : "bg-white"
        }`}
      >
        <input
          type="radio"
          value="single"
          checked={matchType === "single"}
          onChange={() => setMatchType("single")}
          className="hidden"
        />
        Tek Maç
      </label>

      <label 
        className={`flex-1 p-4 border rounded-lg cursor-pointer text-center font-bold transition-colors duration-300 ${
          matchType === "double" ? "bg-blue-500 text-white" : "bg-white"
        }`}
      >
        <input
          type="radio"
          value="double"
          checked={matchType === "double"}
          onChange={() => setMatchType("double")}
          className="hidden"
        />
        Çift Maç
      </label>
    </div>

    <div className="flex justify-between">
      <button
        onClick={() => setStep(4)}
        className="bg-red-500 hover:bg-red-600 text-white font-bold py-3 px-4 rounded-lg transition-colors duration-300 flex items-center"
      >
        <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
        </svg>
        Geri
      </button>
      <button
        onClick={handleMatchTypeSubmit}
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-lg transition-colors duration-300 flex items-center"
      >
        Fikstür Oluştur
        <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </button>
    </div>
  </div>
)}

{step === 6 && (
  <div className="container mx-auto p-4 bg-white">
    <div className="text-center mb-4">
      <div className="bg-gray-100 border border-gray-300 mb-4 p-4">
        <h1 className="text-2xl font-bold">
          {tournamentName} Tur. Fikstürü
          <span className="ml-2" role="img" aria-label="trophy">
            🏆
          </span>
        </h1>
      </div>
      {fixtures.map((round, roundIndex) => (
        <div key={round.round} className="mb-6 border border-gray-300">
          <h2 className="bg-blue-900 text-white text-lg font-semibold p-2 text-center">
            {round.round}. Hafta
          </h2>
          <table className="w-full border-collapse border border-gray-300">
          <thead>
  <tr className="bg-gray-200">
    {["Ev Sahibi", "Skor", "Deplasman", "Durum"].map(
      (header, index) => (
        <th
          key={index}
          className={`p-2 border border-gray-300 ${
            index === 1 ? "w-1/6" : index === 3 ? "w-1/6" : "w-1/3"
          } text-center font-semibold`}
        >
          {header}
        </th>
      )
    )}
  </tr>
</thead>
            <tbody>
              {round.matches.map((match, matchIndex) => {
                const key = `$${roundIndex}-$${matchIndex}`;
                const tempScore = tempScores[key] || {};
                return (
                  <tr
                    key={matchIndex}
                    className={`${
                      matchIndex % 2 === 0 ? "bg-gray-100" : "bg-white"
                    }`}
                  >
                    <td className="p-2 border border-gray-300 text-left">
                      {match.home}
                    </td>
                    <td className="p-2 border border-gray-300 text-center">
                      {match.isCompleted ? (
                        <span>{match.homeScore}:{match.awayScore}</span>
                      ) : (
                        <div className="flex justify-center items-center">
<input
  type="number"
  value={tempScore.homeScore !== undefined ? tempScore.homeScore : ""}
  onChange={(e) =>
    updateTempScore(
      roundIndex,
      matchIndex,
      e.target.value === "" ? undefined : Number(e.target.value),
      tempScore.awayScore
    )
  }
  className="w-8 p-1 border border-gray-300 text-center text-sm"
/>
<span className="mx-1">:</span>
<input
  type="number"
  value={tempScore.awayScore !== undefined ? tempScore.awayScore : ""}
  onChange={(e) =>
    updateTempScore(
      roundIndex,
      matchIndex,
      tempScore.homeScore,
      e.target.value === "" ? undefined : Number(e.target.value)
    )
  }
  className="w-8 p-1 border border-gray-300 text-center text-sm"
/>
                        </div>
                      )}
                    </td>
                    <td className="p-2 border border-gray-300 text-right">
                      {match.away}
                    </td>
                    <td className="p-2 border border-gray-300 text-center">
                      {match.isCompleted ? (
                        <button
  onClick={() => editScore(roundIndex, matchIndex)}
  className="bg-red-500 text-white px-2 py-1 rounded text-xs whitespace-nowrap"
>
  İptal Et
</button>
                      ) : (
                        <button
                          onClick={() =>
                            confirmScore(roundIndex, matchIndex)
                          }
                          className="bg-green-500 text-white px-2 py-1 rounded"
                        >
                          Onay
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ))}
      <h1 className="text-2xl font-bold my-4 text-center">Puan Durumu</h1>
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-700 text-white">
            <th className="p-2 text-center font-semibold border border-gray-300">S</th>
            <th className="p-2 text-left font-semibold border border-gray-300">Takım</th>
            <th className="p-2 text-center font-semibold border border-gray-300">O</th>
            <th className="p-2 text-center font-semibold border border-gray-300">G</th>
            <th className="p-2 text-center font-semibold border border-gray-300">B</th>
            <th className="p-2 text-center font-semibold border border-gray-300">M</th>
            <th className="p-2 text-center font-semibold border border-gray-300">A</th>
            <th className="p-2 text-center font-semibold border border-gray-300">Y</th>
            <th className="p-2 text-center font-semibold border border-gray-300">Av.</th>
            <th className="p-2 text-center font-semibold border border-gray-300">P</th>
          </tr>
        </thead>
        <tbody>
          {leagueTable.map((team, index) => (
            <tr key={team.name} className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}>
              <td className="p-2 text-center border border-gray-300">{index + 1}</td>
              <td className="p-2 border border-gray-300">{team.name}</td>
              <td className="p-2 text-center border border-gray-300">{team.played}</td>
              <td className="p-2 text-center border border-gray-300">{team.won}</td>
              <td className="p-2 text-center border border-gray-300">{team.drawn}</td>
              <td className="p-2 text-center border border-gray-300">{team.lost}</td>
              <td className="p-2 text-center border border-gray-300">{team.goalsFor}</td>
              <td className="p-2 text-center border border-gray-300">{team.goalsAgainst}</td>
              <td className="p-2 text-center border border-gray-300">{team.goalDifference}</td>
              <td className="p-2 text-center border border-gray-300">{team.points}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {champion && (
        <div className="text-center text-2xl font-bold mt-4">
          TEBRİKLER! ŞAMPİYON {champion} 🏆
        </div>
      )}
    <div className="flex justify-between mt-4">
        <button
          onClick={() => setStep(5)}
          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition-colors duration-300 flex items-center"
        >
          <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
          Geri
        </button>
        <button
          onClick={endTournament}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition-colors duration-300 flex items-center"
        >
          Turnuvayı Bitir
          <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3v18h18" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 17V9" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 17V5" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17v-3" />
          </svg>
        </button>
      </div>
    </div>
  </div>
)}


{step === 8 && (
  <div className="p-6 bg-white shadow-md rounded-lg space-y-6">
    <h1 className="text-2xl font-bold text-gray-800 text-center">Turnuva Kurulumu</h1>

    {/* Turnuva İsmi */}
    <div>
      <label htmlFor="tournamentName" className="text-lg font-semibold text-gray-800">
        Turnuva İsmi
      </label>
      <input
        type="text"
        id="tournamentName"
        value={tournamentName}
        onChange={(e) => setTournamentName(e.target.value)}
        placeholder="Turnuva İsmi"
        className="mt-2 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
      />
    </div>

    {/* Katılımcı Sayısı */}
    <div>
      <label htmlFor="participantCount" className="text-lg font-semibold text-gray-800">
        Katılımcı Sayısı
      </label>
      <select
        id="participantCount"
        value={participantCount}
        onChange={(e) => setParticipantCount(e.target.value)}
        className="mt-2 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
      >
        <option value="">Seçiniz</option>
        {[4, 8, 16, 32, 64].map((num) => (
          <option key={num} value={num}>
            {num}
          </option>
        ))}
      </select>
    </div>

    {/* Devam Et Butonu */}
    <div className="flex justify-between items-center mt-4">
  <button
    onClick={() => setStep(1)}
    className="bg-red-500 hover:bg-red-600 text-white font-bold py-3 px-4 rounded-lg transition-colors duration-300 flex items-center"
  >
    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
    </svg>
    Geri
  </button>
  
  <button
    onClick={handleEliminationParticipantCountSubmit}
    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-lg transition-colors duration-300 flex items-center"
  >
    Devam Et
    <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
    </svg>
  </button>
</div>
  </div>
)}

{step === 9 && (
  <div className="p-6 bg-white shadow-md rounded-lg space-y-6">
    <h1 className="text-2xl font-bold text-gray-800 text-center">Katılımcı İsimleri</h1>
    <div className="space-y-4">
      {eliminationParticipants.map((participant, index) => (
        <div key={index} className="flex items-center space-x-4">
          <input
            type="text"
            value={participant.name}
            onChange={(e) =>
              handleEliminationParticipantNameChange(
                index,
                e.target.value,
                participant.isSeeded
              )
            }
            placeholder={`Katılımcı ${index + 1}`}
            className="flex-grow border border-gray-300 rounded-lg shadow-sm py-3 px-4 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={participant.isSeeded}
              onChange={(e) =>
                handleEliminationParticipantNameChange(
                  index,
                  participant.name,
                  e.target.checked
                )
              }
              className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <span className="text-sm text-gray-700">Seri Başı</span>
          </label>
        </div>
      ))}
    </div>
    <div className="flex justify-between items-center mt-6">
  <button
    onClick={() => setStep(8)}
    className="bg-red-500 hover:bg-red-600 text-white font-bold py-3 px-4 rounded-lg transition-colors duration-300 flex items-center"
  >
    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
    </svg>
    Geri
  </button>
  
  <button
    onClick={startEliminationTournament}
    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-lg transition-colors duration-300 flex items-center"
  >
    Turnuvayı Başlat
    <span className="ml-2" role="img" aria-label="start">🏁</span>
  </button>
</div>
  </div>
)}

   {step === 10 && (
  <div className="container mx-auto p-4 bg-white">
    <div className="bg-blue-900 text-white p-2 text-lg font-semibold text-center mb-4">
      <h1>
        {tournamentName}
        <span className="ml-2" role="img" aria-label="trophy">🏆</span>
      </h1>
    </div>
    
    <h2 className="text-xl font-bold mb-4">Tüm Maçlar</h2>
    {allEliminationMatches.map((match, index) => (
      <div key={`past-${index}`} className="mb-6">
        <div className="bg-blue-900 text-white p-2 text-lg font-semibold text-center">
          {getRoundName(allEliminationMatches.length - index)} - Maç {index + 1}
        </div>
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200">
              <th className="p-2 text-left font-semibold border border-gray-300 w-1/3">Ev Sahibi</th>
              <th className="p-2 text-center font-semibold border border-gray-300 w-1/3">Skor</th>
              <th className="p-2 text-right font-semibold border border-gray-300 w-1/3">Deplasman</th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-gray-100">
              <td className="p-2 border border-gray-300 text-left">{match.home.name}</td>
              <td className="p-2 border border-gray-300 text-center">
                {match.homeScore}:{match.awayScore}
              </td>
              <td className="p-2 border border-gray-300 text-right">{match.away.name}</td>
            </tr>
          </tbody>
        </table>
      </div>
    ))}
    
    <h2 className="text-xl font-bold mt-8 mb-4">Mevcut Tur</h2>
    {eliminationFixtures.map((match, index) => (
      <div key={index} className="mb-6">
        <div className="bg-blue-900 text-white p-2 text-lg font-semibold text-center">
          {eliminationRound === 3
            ? `Çeyrek Final Turu ${index + 1}. Maç`
            : eliminationRound === 2
            ? `Yarı Final Turu ${index + 1}. Maç`
            : eliminationRound === 1
            ? `${tournamentName} Final Maçı`
            : `${eliminationRound}. Etap Mücadelesi ${index + 1}. Maç`}
        </div>
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200">
              <th className="p-2 text-left font-semibold border border-gray-300 w-1/4">Ev Sahibi</th>
              <th className="p-2 text-center font-semibold border border-gray-300 w-1/4">Skor</th>
              <th className="p-2 text-right font-semibold border border-gray-300 w-1/4">Deplasman</th>
              <th className="p-2 text-center font-semibold border border-gray-300 w-1/4">Durum</th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-gray-100">
              <td className="p-2 border border-gray-300 text-left">{match.home.name}</td>
              <td className="p-2 border border-gray-300">
                {match.isCompleted ? (
                  <span className="text-center block">
                    {match.homeScore}:{match.awayScore}
                  </span>
                ) : (
                  <div className="flex justify-center items-center">
                    <input
                      type="number"
                      value={match.homeScore !== null ? match.homeScore : ""}
                      onChange={(e) => updateEliminationScore(index, "home", Number(e.target.value))}
                      className="w-12 p-1 border border-gray-300 text-center"
                    />
                    <span className="mx-1">:</span>
                    <input
                      type="number"
                      value={match.awayScore !== null ? match.awayScore : ""}
                      onChange={(e) => updateEliminationScore(index, "away", Number(e.target.value))}
                      className="w-12 p-1 border border-gray-300 text-center"
                    />
                  </div>
                )}
              </td>
              <td className="p-2 border border-gray-300 text-right">{match.away.name}</td>
              <td className="p-2 border border-gray-300 text-center">
                {match.isCompleted ? (
                  <button
                    onClick={() => editEliminationScore(index)}
                    className="bg-red-500 text-white px-2 py-1 rounded"
                  >
                    İptal Et
                  </button>
                ) : (
                  <button
                    onClick={() => confirmEliminationScore(index)}
                    className="bg-green-500 text-white px-2 py-1 rounded"
                  >
                    Onay
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    ))}
    {eliminationWinner && (
      <div className="text-2xl font-bold text-center mt-8 text-green-600">
        {tournamentName} Şampiyonu: {eliminationWinner.name} 🏆
      </div>
    )}
<div className="flex justify-between items-center mt-8">
  <button
    onClick={() => setStep(9)}
    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg transition-colors duration-300 flex items-center"
  >
    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
    </svg>
    Geri
  </button>
  
  <button
    onClick={() => {
      saveTournament();
      endTournament();
    }}
    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg transition-colors duration-300 flex items-center"
  >
    Turnuvayı Bitir
    <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
    </svg>
  </button>
</div>
  </div>
)}
 {step === 11 && (
  <div className="p-6 bg-white shadow-md rounded-lg space-y-6">
    {/* Başlık */}
    <h1 className="text-2xl font-bold text-gray-800 text-center">Turnuva Oluştur</h1>

    {/* Turnuva İsmi */}
    <div>
      <label className="text-lg font-semibold text-gray-800">Turnuva İsmi</label>
      <input
        type="text"
        value={tournamentName}
        onChange={(e) => setTournamentName(e.target.value)}
        placeholder="Turnuva İsmi"
        className="mt-2 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
      />
    </div>

    {/* Katılımcı Sayısı */}
    <div>
      <label className="text-lg font-semibold text-gray-800">Katılımcı Sayısı</label>
      <input
        type="number"
        value={participantCount}
        onChange={(e) => {
          setParticipantCount(e.target.value);
          setGroupParticipants(
            Array(parseInt(e.target.value)).fill({
              name: "",
              isSeeded: false,
            })
          );
        }}
        placeholder="Katılımcı Sayısı"
        className="mt-2 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
      />
    </div>

    {/* Katılımcı İsimleri */}
    <div>
      <label className="text-lg font-semibold text-gray-800">Katılımcı İsimleri</label>
      {groupParticipants.map((participant, index) => (
        <div key={index} className="flex items-center space-x-4 mb-4">
          <input
            type="text"
            value={participant.name}
            onChange={(e) =>
              handleGroupParticipantChange(index, "name", e.target.value)
            }
            placeholder={`Katılımcı ${index + 1}`}
            className="flex-grow p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          />
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={participant.isSeeded}
              onChange={(e) =>
                handleGroupParticipantChange(index, "isSeeded", e.target.checked)
              }
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <span className="text-sm text-gray-700">Seri Başı</span>
          </label>
        </div>
      ))}
    </div>

    {/* Grup Sayısı */}
    <div>
      <label className="text-lg font-semibold text-gray-800">Grup Sayısı</label>
      <select
        value={groupCount}
        onChange={(e) => setGroupCount(e.target.value)}
        className="mt-2 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
      >
        <option value="">Seçiniz</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
      </select>
    </div>

    <div className="flex justify-between items-center mt-4">
  <button
    onClick={() => setStep(1)}
    className="bg-red-500 hover:bg-red-600 text-white font-bold py-3 px-4 rounded-lg transition-colors duration-300 flex items-center"
  >
    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
    </svg>
    Geri
  </button>
  
  <button
    onClick={handleGroupsSubmit}
    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-lg transition-colors duration-300 flex items-center"
  >
    Grupları Oluştur
    <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 10h16M4 14h16M4 18h16" />
    </svg>
  </button>
</div>
  </div>
)}

{step === 12 && (
  <div className="p-6 bg-white shadow-md rounded-lg space-y-6">
    <h1 className="text-2xl font-bold text-gray-800 text-center">
      {tournamentName} Grupları
      <span role="img" aria-label="trophy" className="ml-2">🏆</span>
    </h1>

    {/* Grup Listesi */}
    <div className="flex flex-wrap justify-around">
      {formattedGroups.map((group, index) => (
        <div key={index} className="m-2 w-full md:w-1/3">
          <h2 className="text-center font-semibold text-lg mb-2">
            Grup {String.fromCharCode(65 + index)}
          </h2>
          <table className="table-auto w-full border-collapse">
            <thead>
              <tr className="bg-gray-100">
                <th className="border px-4 py-2">Takım</th>
                <th className="border px-4 py-2">Seri Başı</th>
              </tr>
            </thead>
            <tbody>
              {group.map((participant, pIndex) => (
                <tr
                  key={pIndex}
                  className={pIndex % 2 === 0 ? "bg-gray-100" : ""}
                >
                  <td className="border px-4 py-2">{participant.name}</td>
                  <td className="border px-4 py-2">
                    {participant.isSeeded ? "✅" : "❌"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>

    {/* Maç Sayısı Seçimi */}
    <div>
      <h2 className="text-lg font-semibold text-gray-800 mb-2">Maç Sayısı</h2>
      <div className="flex space-x-4">
        <label
          className={`flex-1 p-3 border rounded-lg text-center cursor-pointer transition-colors duration-300 ${
            matchCount === "single" ? "bg-blue-500 text-white" : "bg-white text-gray-800"
          }`}
        >
          <input
            type="radio"
            value="single"
            checked={matchCount === "single"}
            onChange={() => setMatchCount("single")}
            className="hidden"
          />
          Tek Maç
        </label>
        <label
          className={`flex-1 p-3 border rounded-lg text-center cursor-pointer transition-colors duration-300 ${
            matchCount === "double" ? "bg-blue-500 text-white" : "bg-white text-gray-800"
          }`}
        >
          <input
            type="radio"
            value="double"
            checked={matchCount === "double"}
            onChange={() => setMatchCount("double")}
            className="hidden"
          />
          Çift Maç
        </label>
      </div>
    </div>

    {/* Gruptan Çıkacak Takım Sayısı */}
    <div>
      <h2 className="text-lg font-semibold text-gray-800 mb-2">Gruptan Çıkacak Takım Sayısı</h2>
      <div className="flex space-x-4">
        <label
          className={`flex-1 p-3 border rounded-lg text-center cursor-pointer transition-colors duration-300 ${
            teamsAdvancing === "2" ? "bg-blue-500 text-white" : "bg-white text-gray-800"
          }`}
        >
          <input
            type="radio"
            value="2"
            checked={teamsAdvancing === "2"}
            onChange={() => setTeamsAdvancing("2")}
            className="hidden"
          />
          2 Takım
        </label>
        <label
          className={`flex-1 p-3 border rounded-lg text-center cursor-pointer transition-colors duration-300 ${
            teamsAdvancing === "3" ? "bg-blue-500 text-white" : "bg-white text-gray-800"
          }`}
        >
          <input
            type="radio"
            value="3"
            checked={teamsAdvancing === "3"}
            onChange={() => setTeamsAdvancing("3")}
            className="hidden"
          />
          3 Takım
        </label>
        <label
          className={`flex-1 p-3 border rounded-lg text-center cursor-pointer transition-colors duration-300 ${
            teamsAdvancing === "4" ? "bg-blue-500 text-white" : "bg-white text-gray-800"
          }`}
        >
          <input
            type="radio"
            value="4"
            checked={teamsAdvancing === "4"}
            onChange={() => setTeamsAdvancing("4")}
            className="hidden"
          />
          4 Takım
        </label>
      </div>
    </div>

    {/* Fikstür Oluştur Butonu */}
    <div className="flex justify-between items-center mt-6">
  <button
    onClick={() => setStep(11)}
    className="bg-red-500 hover:bg-red-600 text-white font-bold py-3 px-4 rounded-lg transition-colors duration-300 flex items-center"
  >
    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
    </svg>
    Geri
  </button>
  
  <button
    onClick={() => setStep(13)}
    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-lg transition-colors duration-300 flex items-center"
    disabled={!matchCount || !teamsAdvancing}
  >
    Fikstür Oluştur
    <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
    </svg>
  </button>
</div>
  </div>
)}

{step === 13 && (
        <div className="container mx-auto p-4 bg-white">
          <div className="flex justify-center mb-4">
            {formattedGroups.map((group, index) => (
              <button
                key={index}
                onClick={() => setSelectedGroup(index)}
                className={`px-4 py-2 mr-2 rounded ${
                  selectedGroup === index
                    ? 'bg-green-500 text-white'
                    : 'bg-gray-200 text-black'
                }`}
              >
                Grup {String.fromCharCode(65 + index)}
              </button>
            ))}
          </div>

          <h1 className="text-2xl font-bold mb-4 text-center">
            {tournamentName} Grup {String.fromCharCode(65 + selectedGroup)} Fikstürü
            <span className="ml-2" role="img" aria-label="trophy">
              🏆
            </span>
          </h1>

          {groupFixtures[selectedGroup] &&
            groupFixtures[selectedGroup].map((week, weekIndex) => (
              <div key={weekIndex} className="mb-6">
                <div className="bg-blue-900 text-white p-2 text-lg font-semibold text-center">
                  {week.week}. Hafta
                </div>
                <table className="w-full border-collapse border border-gray-300">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="p-2 text-left font-semibold border border-gray-300 w-1/4">Ev Sahibi</th>
                      <th className="p-2 text-center font-semibold border border-gray-300 w-1/4">Skor</th>
                      <th className="p-2 text-right font-semibold border border-gray-300 w-1/4">Deplasman</th>
                      <th className="p-2 text-center font-semibold border border-gray-300 w-1/4">Durum</th>
                    </tr>
                  </thead>
                  <tbody>
                    {week.matches.map((match, matchIndex) => (
                      <tr key={matchIndex} className={matchIndex % 2 === 0 ? "bg-gray-100" : "bg-white"}>
                        <td className="p-2 border border-gray-300 text-left">{match.home.name}</td>
                        <td className="p-2 border border-gray-300">
                          {match.isConfirmed ? (
                            <span className="text-center block">
                              {match.homeScore}:{match.awayScore}
                            </span>
                          ) : (
                            <div className="flex justify-center items-center">
                              <input
                                type="number"
                                value={match.homeScore !== null ? match.homeScore : ""}
                                onChange={(e) => updateGroupScore(selectedGroup, weekIndex, matchIndex, "home", e.target.value)}
                                className="w-12 p-1 border border-gray-300 text-center"
                              />
                              <span className="mx-1">:</span>
                              <input
                                type="number"
                                value={match.awayScore !== null ? match.awayScore : ""}
                                onChange={(e) => updateGroupScore(selectedGroup, weekIndex, matchIndex, "away", e.target.value)}
                                className="w-12 p-1 border border-gray-300 text-center"
                              />
                            </div>
                          )}
                        </td>
                        <td className="p-2 border border-gray-300 text-right">{match.away.name}</td>
                        <td className="p-2 border border-gray-300 text-center">
                          {match.isConfirmed ? (
                            <button
                              onClick={() => editGroupScore(selectedGroup, weekIndex, matchIndex)}
                              className="bg-red-500 text-white px-2 py-1 rounded"
                            >
                              İptal Et
                            </button>
                          ) : (
                            <button
                              onClick={() => confirmGroupScore(selectedGroup, weekIndex, matchIndex)}
                              className="bg-green-500 text-white px-2 py-1 rounded"
                            >
                              Onay
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}

          <h2 className="text-2xl font-bold mt-8 mb-4">Puan Durumu</h2>
          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-700 text-white">
                <th className="p-2 text-center font-semibold border border-gray-300">S</th>
                <th className="p-2 text-left font-semibold border border-gray-300">Takım</th>
                <th className="p-2 text-center font-semibold border border-gray-300">O</th>
                <th className="p-2 text-center font-semibold border border-gray-300">G</th>
                <th className="p-2 text-center font-semibold border border-gray-300">B</th>
                <th className="p-2 text-center font-semibold border border-gray-300">M</th>
                <th className="p-2 text-center font-semibold border border-gray-300">A</th>
                <th className="p-2 text-center font-semibold border border-gray-300">Y</th>
                <th className="p-2 text-center font-semibold border border-gray-300">Av.</th>
                <th className="p-2 text-center font-semibold border border-gray-300">P</th>
              </tr>
            </thead>
            <tbody>
              {groupStandings[selectedGroup] &&
                groupStandings[selectedGroup].map((team, teamIndex) => (
                  <tr key={teamIndex} className={teamIndex % 2 === 0 ? "bg-gray-100" : "bg-white"}>
                    <td className="p-2 text-center border border-gray-300">{teamIndex + 1}</td>
                    <td className="p-2 border border-gray-300">{team.name}</td>
                    <td className="p-2 text-center border border-gray-300">{team.played}</td>
                    <td className="p-2 text-center border border-gray-300">{team.won}</td>
                    <td className="p-2 text-center border border-gray-300">{team.drawn}</td>
                    <td className="p-2 text-center border border-gray-300">{team.lost}</td>
                    <td className="p-2 text-center border border-gray-300">{team.goalsFor}</td>
                    <td className="p-2 text-center border border-gray-300">{team.goalsAgainst}</td>
                    <td className="p-2 text-center border border-gray-300">{team.goalDifference}</td>
                    <td className="p-2 text-center border border-gray-300">{team.points}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      {step === 14 && (
        <div className="container mx-auto p-4 bg-white">
          <h2 className="text-2xl font-bold mb-4 text-center">{tournamentName} Eşleşmeleri</h2>

          {Object.keys(groupStandings).map((groupIndex) => (
            <div key={groupIndex} className="mb-6">
              <div className="bg-blue-900 text-white p-2 text-lg font-semibold text-center">
                Grup {String.fromCharCode(65 + parseInt(groupIndex))} Puan Durumu
              </div>
              <table className="w-full border-collapse border border-gray-300">
                <thead>
                  <tr className="bg-gray-700 text-white">
                    <th className="p-2 text-center font-semibold border border-gray-300">S</th>
                    <th className="p-2 text-left font-semibold border border-gray-300">Takım</th>
                    <th className="p-2 text-center font-semibold border border-gray-300">O</th>
                    <th className="p-2 text-center font-semibold border border-gray-300">G</th>
                    <th className="p-2 text-center font-semibold border border-gray-300">B</th>
                    <th className="p-2 text-center font-semibold border border-gray-300">M</th>
                    <th className="p-2 text-center font-semibold border border-gray-300">A</th>
                    <th className="p-2 text-center font-semibold border border-gray-300">Y</th>
                    <th className="p-2 text-center font-semibold border border-gray-300">Av.</th>
                    <th className="p-2 text-center font-semibold border border-gray-300">P</th>
                  </tr>
                </thead>
                <tbody>
                  {groupStandings[groupIndex].map((team, teamIndex) => (
                    <tr key={teamIndex} className={teamIndex % 2 === 0 ? "bg-gray-100" : "bg-white"}>
                      <td className="p-2 text-center border border-gray-300">{teamIndex + 1}</td>
                      <td className="p-2 border border-gray-300">{team.name}</td>
                      <td className="p-2 text-center border border-gray-300">{team.played}</td>
                      <td className="p-2 text-center border border-gray-300">{team.won}</td>
                      <td className="p-2 text-center border border-gray-300">{team.drawn}</td>
                      <td className="p-2 text-center border border-gray-300">{team.lost}</td>
                      <td className="p-2 text-center border border-gray-300">{team.goalsFor}</td>
                      <td className="p-2 text-center border border-gray-300">{team.goalsAgainst}</td>
                      <td className="p-2 text-center border border-gray-300">{team.goalDifference}</td>
                      <td className="p-2 text-center border border-gray-300">{team.points}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}

          {knockoutFixtures.map((match, index) => (
            <div key={index} className="mb-6">
              <div className="bg-blue-900 text-white p-2 text-lg font-semibold text-center">
                {`${tournamentName} ${getRoundName(knockoutFixtures.length)} ${index + 1}. Maç`}
              </div>
              <table className="w-full border-collapse border border-gray-300">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="p-2 text-left font-semibold border border-gray-300 w-1/4">Ev Sahibi</th>
                    <th className="p-2 text-center font-semibold border border-gray-300 w-1/4">Skor</th>
                    <th className="p-2 text-right font-semibold border border-gray-300 w-1/4">Deplasman</th>
                    <th className="p-2 text-center font-semibold border border-gray-300 w-1/4">Durum</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-gray-100">
                    <td className="p-2 border border-gray-300 text-left">{match.home.name}</td>
                    <td className="p-2 border border-gray-300">
                      {match.isConfirmed ? (
                        <span className="text-center block">
                          {match.homeScore}:{match.awayScore}
                        </span>
                      ) : (
                        <div className="flex justify-center items-center">
                          <input
                            type="number"
                            value={match.homeScore !== null ? match.homeScore : ""}
                            onChange={(e) => updateKnockoutScore(index, "home", e.target.value)}
                            className="w-12 p-1 border border-gray-300 text-center"
                          />
                          <span className="mx-1">:</span>
                          <input
                            type="number"
                            value={match.awayScore !== null ? match.awayScore : ""}
                            onChange={(e) => updateKnockoutScore(index, "away", e.target.value)}
                            className="w-12 p-1 border border-gray-300 text-center"
                          />
                        </div>
                      )}
                    </td>
                    <td className="p-2 border border-gray-300 text-right">{match.away.name}</td>
                    <td className="p-2 border border-gray-300 text-center">
                      {match.isConfirmed ? (
                        <button
                          onClick={() => editKnockoutScore(index)}
                          className="bg-red-500 text-white px-2 py-1 rounded"
                        >
                          İptal Et
                        </button>
                      ) : (
                        <button
                          onClick={() => confirmKnockoutScore(index)}
                          className="bg-green-500 text-white px-2 py-1 rounded"
                        >
                          Onay
                        </button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}

          {champion && (
            <div className="text-2xl font-bold text-center mt-8 text-green-600">
              TEBRİKLER! {tournamentName} ŞAMPİYONU {champion} 🏆
            </div>
          )}

          <button
            onClick={endTournament}
            className="w-full bg-red-500 text-white py-2 mt-8 rounded"
          >
            Turnuvayı Bitir
          </button>
        </div>
      )}

{step === 15 && (
  <>
    {selectedTournament ? (
      <div className="p-6 bg-white shadow-md rounded-lg space-y-6">
        <h2 className="text-2xl font-bold text-center">{selectedTournament.name} Turnuvasının Detayları</h2>
        <h3 className="text-lg font-semibold text-center">Şampiyon: {selectedTournament.champion}</h3>

        {selectedTournament.type === 'groups' && selectedTournament.groups?.length > 0 ? (
          selectedTournament.groups.map((group, index) => (
            <div key={index} className="space-y-4">
              <h4 className="text-lg font-bold text-gray-700">{group.name} Fikstürü</h4>

              {group.fixtures?.length > 0 ? (
                <table className="min-w-full bg-white border border-gray-200">
                  <thead className="bg-gray-200">
                    <tr>
                      <th className="p-2 text-left font-semibold">Ev Sahibi</th>
                      <th className="p-2 text-center font-semibold">Skor</th>
                      <th className="p-2 text-right font-semibold">Deplasman</th>
                    </tr>
                  </thead>
                  <tbody>
                    {group.fixtures.map((match, matchIndex) => (
                      <tr key={matchIndex} className="border-t border-gray-200">
                        <td className="p-2">{match.home}</td>
                        <td className="p-2 text-center">{match.homeScore} - {match.awayScore}</td>
                        <td className="p-2 text-right">{match.away}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="text-gray-500">Fikstür bulunamadı.</p>
              )}

              <h4 className="text-lg font-bold text-gray-700">Puan Durumu</h4>
              {group.standings?.length > 0 ? (
                <table className="min-w-full bg-white border border-gray-200">
                  <thead className="bg-gray-200">
                    <tr>
                      <th className="p-2 font-semibold">Takım</th>
                      <th className="p-2 text-center font-semibold">O</th>
                      <th className="p-2 text-center font-semibold">G</th>
                      <th className="p-2 text-center font-semibold">B</th>
                      <th className="p-2 text-center font-semibold">M</th>
                      <th className="p-2 text-center font-semibold">A</th>
                      <th className="p-2 text-center font-semibold">Y</th>
                      <th className="p-2 text-center font-semibold">Av.</th>
                      <th className="p-2 text-center font-semibold">P</th>
                    </tr>
                  </thead>
                  <tbody>
                    {group.standings.map((team, teamIndex) => (
                      <tr key={teamIndex} className="border-t border-gray-200">
                        <td className="p-2">{team.name}</td>
                        <td className="p-2 text-center">{team.played}</td>
                        <td className="p-2 text-center">{team.won}</td>
                        <td className="p-2 text-center">{team.drawn}</td>
                        <td className="p-2 text-center">{team.lost}</td>
                        <td className="p-2 text-center">{team.goalsFor}</td>
                        <td className="p-2 text-center">{team.goalsAgainst}</td>
                        <td className="p-2 text-center">{team.goalDifference}</td>
                        <td className="p-2 text-center">{team.points}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="text-gray-500">Puan durumu bulunamadı.</p>
              )}
            </div>
          ))
        ) : selectedTournament.type === 'league' ? (
          <>
            <h4 className="text-lg font-bold text-gray-700">Fikstür</h4>
            {selectedTournament.fixtures?.length > 0 ? (
              <table className="min-w-full bg-white border border-gray-200">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="p-2 text-left font-semibold">Ev Sahibi</th>
                    <th className="p-2 text-center font-semibold">Skor</th>
                    <th className="p-2 text-right font-semibold">Deplasman</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedTournament.fixtures.map((match, matchIndex) => (
                    <tr key={matchIndex} className="border-t border-gray-200">
                      <td className="p-2">{match.home}</td>
                      <td className="p-2 text-center">{match.homeScore} - {match.awayScore}</td>
                      <td className="p-2 text-right">{match.away}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-gray-500">Fikstür bulunamadı.</p>
            )}

            <h4 className="text-lg font-bold text-gray-700">Puan Durumu</h4>
            {selectedTournament.leagueTable?.length > 0 ? (
              <table className="min-w-full bg-white border border-gray-200">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="p-2 font-semibold">Takım</th>
                    <th className="p-2 text-center font-semibold">O</th>
                    <th className="p-2 text-center font-semibold">G</th>
                    <th className="p-2 text-center font-semibold">B</th>
                    <th className="p-2 text-center font-semibold">M</th>
                    <th className="p-2 text-center font-semibold">A</th>
                    <th className="p-2 text-center font-semibold">Y</th>
                    <th className="p-2 text-center font-semibold">Av.</th>
                    <th className="p-2 text-center font-semibold">P</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedTournament.leagueTable.map((team, teamIndex) => (
                    <tr key={teamIndex} className="border-t border-gray-200">
                      <td className="p-2">{team.name}</td>
                      <td className="p-2 text-center">{team.played}</td>
                      <td className="p-2 text-center">{team.won}</td>
                      <td className="p-2 text-center">{team.drawn}</td>
                      <td className="p-2 text-center">{team.lost}</td>
                      <td className="p-2 text-center">{team.goalsFor}</td>
                      <td className="p-2 text-center">{team.goalsAgainst}</td>
                      <td className="p-2 text-center">{team.goalDifference}</td>
                      <td className="p-2 text-center">{team.points}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-gray-500">Puan durumu bulunamadı.</p>
            )}
          </>
        ) : selectedTournament.type === 'elimination' ? (
          <>
            <h3 className="text-lg font-bold text-gray-700">Eleme Maçları</h3>
            {selectedTournament.knockoutFixtures?.length > 0 ? (
              <table className="min-w-full bg-white border border-gray-200">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="p-2 text-left font-semibold">Ev Sahibi</th>
                    <th className="p-2 text-center font-semibold">Skor</th>
                    <th className="p-2 text-right font-semibold">Deplasman</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedTournament.knockoutFixtures.map((match, index) => (
                    <tr key={index} className="border-t border-gray-200">
                      <td className="p-2">{match.home}</td>
                      <td className="p-2 text-center">{match.homeScore} - {match.awayScore}</td>
                      <td className="p-2 text-right">{match.away}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-gray-500">Eleme maçları bulunamadı.</p>
            )}
          </>
        ) : selectedTournament.type === 'challenge' ? (
          <>
            <h3 className="text-lg font-bold text-gray-700">Meydan Okuma Maçları</h3>
            {selectedTournament.challengeFixtures?.length > 0 ? (
              <table className="min-w-full bg-white border border-gray-200">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="p-2 text-left font-semibold">Ev Sahibi</th>
                    <th className="p-2 text-center font-semibold">Skor</th>
                    <th className="p-2 text-right font-semibold">Deplasman</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedTournament.challengeFixtures.map((match, index) => (
                    <tr key={index} className="border-t border-gray-200">
                      <td className="p-2">{match.home}</td>
                      <td className="p-2 text-center">{match.homeScore} - {match.awayScore}</td>
                      <td className="p-2 text-right">{match.away}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-gray-500">Meydan okuma maçları bulunamadı.</p>
            )}
          </>
        ) : (
          <p className="text-gray-500">Turnuva tipi bulunamadı.</p>
        )}

        <button onClick={() => setSelectedTournament(null)} className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600">
          Geri
        </button>
      </div>
    ) : (
      <div className="p-6 bg-white shadow-md rounded-lg space-y-6">
      <div className="text-2xl font-bold text-center text-gray-800">Kaydedilen Turnuvalar 🏆</div>

      {currentTournaments.length > 0 ? (
        <ul className="space-y-4">
          {currentTournaments.map((tournament) => (
            <li
              key={tournament._id}
              className="p-4 bg-gray-100 border border-gray-300 rounded-lg"
            >
              <div className="flex justify-between items-center">
                <span>{getEmojiForTournamentType(tournament.type)} {tournament.name}</span>
                <span className="text-sm text-gray-600">({new Date(tournament.createdAt).toLocaleDateString()})</span>
              </div>
              <div className="text-sm text-gray-500 mb-2">
                Şampiyon: {tournament.champion || "Henüz belirlenmedi"}
              </div>
              <button
                onClick={() => fetchTournamentDetails(tournament._id)}
                className="mt-2 bg-red-500 text-white py-1 px-3 rounded text-sm hover:bg-red-600 transition duration-300"
              >
                Detayları Gör
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-500 text-center">Henüz tamamlanmış turnuva yok</p>
      )}

      <div className="flex justify-between items-center">
        <button onClick={() => setStep(1)} className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
          Geri
        </button>
        <div className="space-x-2">
          {pageNumbers.map((number) => (
            <button
              key={number}
              onClick={() => paginate(number)}
              className={`py-2 px-4 rounded ${currentPage === number ? 'bg-blue-700 text-white' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
            >
              {number}
            </button>
            ))}
          </div>
        </div>
      </div>
    )}
  </>
)}

{step === 7 && (
  <>
{challengeStep === 1 && (
  <div className="p-6 bg-white shadow-md rounded-lg space-y-6">
    <div className="bg-gray-100 border border-gray-300 p-4 text-center rounded-lg">
      <h1 className="text-2xl font-bold text-gray-800">
        Gladyatör İsimleri
        <span role="img" aria-label="trophy" className="ml-2">🏆</span>
      </h1>
    </div>

    <div>
      <label htmlFor="gladiator1" className="text-lg font-semibold text-gray-800">
        Gladyatör 1
      </label>
      <input
        type="text"
        id="gladiator1"
        value={gladiator1}
        onChange={(e) => setGladiator1(e.target.value)}
        placeholder="Gladyatör 1"
        className="mt-2 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
      />
    </div>

    <div>
      <label htmlFor="gladiator2" className="text-lg font-semibold text-gray-800">
        Gladyatör 2
      </label>
      <input
        type="text"
        id="gladiator2"
        value={gladiator2}
        onChange={(e) => setGladiator2(e.target.value)}
        placeholder="Gladyatör 2"
        className="mt-2 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
      />
    </div>

    <div className="flex justify-between space-x-4">
      <label 
        className={`flex-1 p-3 border border-gray-300 rounded-lg text-center cursor-pointer transition-colors duration-300 ${bestOf === "3" ? 'bg-blue-500 text-white' : 'bg-white text-gray-800'}`}
        onClick={() => setBestOf("3")}
      >
        <input
          type="radio"
          value="3"
          checked={bestOf === "3"}
          onChange={() => setBestOf("3")}
          className="hidden"
        />
        Best Of 3 💪
      </label>

      <label 
        className={`flex-1 p-3 border border-gray-300 rounded-lg text-center cursor-pointer transition-colors duration-300 ${bestOf === "5" ? 'bg-blue-500 text-white' : 'bg-white text-gray-800'}`}
        onClick={() => setBestOf("5")}
      >
        <input
          type="radio"
          value="5"
          checked={bestOf === "5"}
          onChange={() => setBestOf("5")}
          className="hidden"
        />
        Best Of 5 💪
      </label>
    </div>

    <button
      onClick={() => {
        if (gladiator1 && gladiator2 && bestOf) {
          setChallengeStep(2);
          generateChallengeFixtures();
        }
      }}
      className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-lg transition-colors duration-300"
    >
      Meydan Okumayı Başlat
    </button>
  </div>
)}
    
    {challengeStep === 2 && (
      <div className="container mx-auto p-4 bg-white">
        <div className="bg-gray-200 border border-gray-300 mb-4 text-center w-full">
          <h1 className="text-2xl font-bold p-2">
            {gladiator1} ⚔️ {gladiator2}
          </h1>
        </div>
        
        {challengeFixtures.map((match, index) => (
          <div key={index} className="mb-6">
            <div className="bg-blue-900 text-white p-2 text-lg font-semibold text-center">
              {match.round}. Müsabaka ⚔️
            </div>
            <table className="w-full border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-200">
                  <th className="p-2 text-left font-semibold border border-gray-300 w-1/4">Ev Sahibi</th>
                  <th className="p-2 text-center font-semibold border border-gray-300 w-1/4">Skor</th>
                  <th className="p-2 text-right font-semibold border border-gray-300 w-1/4">Deplasman</th>
                  <th className="p-2 text-center font-semibold border border-gray-300 w-1/4">Durum</th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-gray-100">
                  <td className="p-2 border border-gray-300 text-left">{match.home}</td>
                  <td className="p-2 border border-gray-300">
                    {match.isCompleted ? (
                      <span className="text-center block">
                        {match.homeScore}:{match.awayScore}
                      </span>
                    ) : (
                      <div className="flex justify-center items-center">
                        <input
                          type="number"
                          value={match.homeScore !== null ? match.homeScore : ""}
                          onChange={(e) => updateChallengeScore(index, "home", Number(e.target.value))}
                          className="w-12 p-1 border border-gray-300 text-center"
                          disabled={winner !== null}
                        />
                        <span className="mx-1">:</span>
                        <input
                          type="number"
                          value={match.awayScore !== null ? match.awayScore : ""}
                          onChange={(e) => updateChallengeScore(index, "away", Number(e.target.value))}
                          className="w-12 p-1 border border-gray-300 text-center"
                          disabled={winner !== null}
                        />
                      </div>
                    )}
                  </td>
                  <td className="p-2 border border-gray-300 text-right">{match.away}</td>
                  <td className="p-2 border border-gray-300 text-center">
                    {match.isCompleted ? (
                      <button
                        onClick={() => editChallengeScore(index)}
                        className="bg-red-500 text-white px-2 py-1 rounded"
                        disabled={winner !== null}
                      >
                        İptal Et
                      </button>
                    ) : (
                      <button
                        onClick={() => confirmChallengeScore(index)}
                        className="bg-green-500 text-white px-2 py-1 rounded"
                        disabled={winner !== null}
                      >
                        Onay
                      </button>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}

        <h1 className="text-2xl font-bold mt-8 mb-4">Puan Durumu</h1>
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-700 text-white">
              <th className="p-2 text-left font-semibold border border-gray-300">Gladyatör</th>
              <th className="p-2 text-center font-semibold border border-gray-300">G</th>
              <th className="p-2 text-center font-semibold border border-gray-300">M</th>
              <th className="p-2 text-center font-semibold border border-gray-300">P</th>
            </tr>
          </thead>
          <tbody>
            {challengeLeagueTable.map((gladiator, index) => (
              <tr key={gladiator.name} className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}>
                <td className="p-2 border border-gray-300">{gladiator.name}</td>
                <td className="p-2 border border-gray-300 text-center">{gladiator.won}</td>
                <td className="p-2 border border-gray-300 text-center">{gladiator.lost}</td>
                <td className="p-2 border border-gray-300 text-center">{gladiator.points}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {winner && (
          <div className="text-2xl font-bold text-center mt-8 text-green-600">
            Kazanan: {winner} 💪⚔️
          </div>
        )}
        <button
          onClick={() => {
            saveTournament();
            endTournament();
          }}
          className="w-full bg-red-500 text-white py-2 mt-8 rounded"
        >
          Turnuvayı Bitir
        </button>
      </div>
    )}
  </>
)}

  
    </div>
  );
};

export default App;