const styles = {
  app: {
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    maxWidth: '60%',  // Uygulamanın genişliğini %60 yap
    margin: '0 auto',  // Ortalamak için
    minHeight: '100vh',  // Minimum yüksekliği tarayıcı yüksekliği kadar yap
    backgroundColor: '#f0f0f0',  // Arka plan rengini biraz daha açık yap
  },
  backButton: {
    backgroundColor: "#f44336", // Kırmızı renk
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "16px",
    margin: "10px 0",
  },
  tournamentDetails: {
    width: '100%',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    boxSizing: 'border-box',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  },
  paginationButton: {
    backgroundColor: '#007BFF',
    border: 'none',
    color: 'white',
    padding: '8px 16px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '14px',
    margin: '4px 2px',
    cursor: 'pointer',
    borderRadius: '4px',
  },
  thTd: {
    padding: '10px',
    border: '1px solid #ddd',
    textAlign: 'center',
  },
  authModal: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  authContent: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  container: {
    maxWidth: "800px",
    width: "90%",
    margin: "0 auto",
    padding: "20px",
    boxSizing: "border-box",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
  },
  input: {
    width: "100%",
    padding: "12px",
    marginBottom: "20px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    fontSize: "16px",
    boxSizing: "border-box",
  },
  button: {
    backgroundColor: "#007BFF",
    border: "none",
    color: "white",
    padding: "8px 16px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "14px",
    margin: "4px 2px",
    cursor: "pointer",
    borderRadius: "4px",
    boxSizing: "border-box",
    transition: "background-color 0.3s",
  },
  buttonHover: {
    backgroundColor: "#0056b3",
  },
  greenButton: {
    backgroundColor: "#4CAF50",
  },
  formGroup: {
    marginBottom: "20px",
  },
  label: {
    marginBottom: "10px",
    fontSize: "18px",
    fontWeight: "bold",
    display: "block",
  },
  header: {
    backgroundColor: "#e0e0e0", // Arka plan rengini daha belirgin yap
    padding: "12px",
    marginBottom: "20px",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "space-between",  // Sol ve sağa dağıtmak için
    alignItems: "center",
    height: "60px", // Yüksekliği artır
  },
  headerTitle: {
    margin: 0,
    fontSize: "24px",  // Font boyutunu artır
    fontWeight: "bold",
    textAlign: "center",  // Ortalamak için
    flex: 1,
    backgroundColor: "#f2f2f2", // Arka plan rengini belirgin yap
    padding: "10px", // İçerik boşluğunu artır
    borderRadius: "4px",
  },
  headerButtonContainer: {
    display: 'flex',
    gap: '10px',  // Butonlar arasında boşluk
  },
  cacheButton: {
    backgroundColor: "#f44336",
    border: "none",
    color: "white",
    padding: "8px 16px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "14px",
    margin: "4px 2px",
    cursor: "pointer",
    borderRadius: "4px",
    boxSizing: "border-box",
    transition: "background-color 0.3s",
  },
  optionContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "20px",
  },
  optionBox: {
    flex: "1",
    padding: "20px",
    margin: "5px",
    border: "1px solid #ccc",
    borderRadius: "8px",  // Kenarları yuvarlat
    textAlign: "center",
    cursor: "pointer",
    transition: "background-color 0.3s",
    userSelect: "none",
    backgroundColor: "#ffffff",  // Arka plan rengini beyaz yap
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",  // Hafif gölge ekle
  },
  optionBoxHover: {
    backgroundColor: "#e0e0e0",  // Üzerine gelince renk değiştir
  },
  optionBoxSelected: {
    backgroundColor: "#007BFF",
    color: "white",
    borderColor: "#007BFF",
  },
  hiddenInput: {
    display: "none",
  },
  sectionTitleBox: {
    padding: "10px",
    marginBottom: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    backgroundColor: "#e9ecef",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "bold",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "20px",
    fontSize: "14px",
  },
  th: {
    padding: "12px",
    textAlign: "center",
    color: "black",
    borderBottom: "1px solid #ddd",
    backgroundColor: "white",
  },
  td: {
    padding: "8px",
    textAlign: "center",
    borderBottom: "1px solid #ddd",
    borderLeft: "none",
    borderRight: "none",
  },
  scoreInput: {
    width: "40px",
    padding: "5px",
    margin: "0 2px",
    border: "1px solid #4CAF50",
    borderRadius: "4px",
    fontSize: "14px",
  },
  confirmButton: {
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    padding: "6px 12px",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
  },
  cancelButton: {
    backgroundColor: "#f44336",
    color: "white",
    border: "none",
    padding: "6px 12px",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
  },
  weekHeader: {
    backgroundColor: "#063970",
    color: "white",
    padding: "8px",
    marginBottom: "0",
    fontSize: "16px",
    fontWeight: "bold",
  },
  tournamentHeader: {
    backgroundColor: "#f2f2f2",
    padding: "12px",
    marginBottom: "10px",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    boxSizing: "border-box",
    border: "1px solid #ccc",
    height: "100px",
  },
  tournamentTitle: {
    margin: 0,
    fontSize: "24px",  // Font boyutunu artır
    fontWeight: "bold",
    textAlign: "center",  // Ortalamak için
  },
  trophyIcon: {
    fontSize: "24px",
    marginLeft: "10px",
  },
  systemContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    boxSizing: "border-box",
  },
  systemBox: {
    width: "100%",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    textAlign: "center",
    cursor: "pointer",
    transition: "background-color 0.3s",
    marginBottom: "10px",
    boxSizing: "border-box",
    height: "120px",  // Yüksekliği artır
    backgroundColor: "#ffffff",  // Arka plan rengini beyaz yap
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",  // Hafif gölge ekle
  },
  systemBoxHover: {
    backgroundColor: "#e0e0e0",  // Üzerine gelince renk değiştir
  },
  smallButton: {
    padding: "8px 16px",
    margin: "0 4px",
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
  },
  tournamentListItem: {
    padding: '10px',
    margin: '5px 0',
    border: '1px solid #ccc',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  tournamentListHeader: {
    backgroundColor: "#f2f2f2",
    padding: "12px",
    marginBottom: "20px",
    borderRadius: "4px",
    textAlign: "center",
    fontSize: "24px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tournamentList: {
    listStyleType: "none",
    padding: "0",
  },

  tournamentListItemHover: {
    backgroundColor: '#e0e0e0',
  },
  tournamentListItemChampion: {
    fontWeight: 'bold',
    fontSize: '14px',
  }
  
};

export const tableHeaderStyles = {
  s: { ...styles.th, backgroundColor: "#808080", width: "5%" },
  team: { ...styles.th, backgroundColor: "#008CBA", width: "25%" },
  ogbm: { ...styles.th, backgroundColor: "#4CAF50", width: "7%" },
  a: { ...styles.th, backgroundColor: "#FFD700", width: "7%" },
  y: { ...styles.th, backgroundColor: "#FF4136", width: "7%" },
  av: { ...styles.th, backgroundColor: "#FF851B", width: "7%" },
  p: { ...styles.th, backgroundColor: "#0074D9", width: "7%" },
};

export const mobileStyles = {
  container: {
    width: "100%",
    padding: "5px",
    margin: "0 auto",
    boxSizing: "border-box",
  },
  table: {
    fontSize: "12px",
    width: "100%",
    overflowX: "auto",
    display: "block",
  },
  th: {
    padding: "6px 4px",
    whiteSpace: "nowrap",
  },
  td: {
    padding: "4px 2px",
    whiteSpace: "nowrap",
  },
  scoreInput: {
    width: "25px",
    padding: "2px",
    fontSize: "11px",
  },
  confirmButton: {
    padding: "3px 6px",
    fontSize: "11px",
    backgroundColor: "#4CAF50",
    color: "white",
    margin: "2px",
  },
  cancelButton: {
    padding: "3px 6px",
    fontSize: "11px",
    backgroundColor: "#f44336",
    color: "white",
    margin: "2px",
  },
  awayTeam: {
    textAlign: "left",
  },
  actionButton: {
    display: "inline-block",
    margin: "2px",
    padding: "4px 8px",
    fontSize: "12px",
    border: "none",
  },
  weekHeader: {
    ...styles.weekHeader,
    fontSize: "14px",
    padding: "6px",
  },
  tournamentHeader: {
    ...styles.tournamentHeader,
    padding: "8px",
    flexDirection: "column",
    alignItems: "center",
    height: "auto",
  },
  tournamentTitle: {
    ...styles.tournamentTitle,
    fontSize: "16px",
    marginBottom: "5px",
  },
  trophyIcon: {
    ...styles.trophyIcon,
    fontSize: "18px",
  },
  header: {
    flexDirection: "column",
    alignItems: "center",
    height: "auto",
    padding: "10px",
  },
  headerTitle: {
    fontSize: "18px",
    marginBottom: "10px",
    textAlign: "center",
  },
  headerButtonContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "5px",
  },
  optionContainer: {
    flexDirection: "column",
  },
  optionBox: {
    margin: "5px 0",
  },
  systemBox: {
    height: "auto",
    padding: "10px",
  },
};

export default styles;